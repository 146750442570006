import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Metadata, Page } from '~/components/Layout'
import PageSection from '~/sections/PageSection'

const HomePage = ({ data: { home } }) => {
  const { metadata, sections = [] } = home

  return (
    <Page isHome>
      <Metadata
        title={metadata.title}
        description={metadata.description.description}
        image={metadata.image.file.url}
      />
      {sections.map(section => (
        <PageSection key={section.id} section={section} />
      ))}
    </Page>
  )
}

export default HomePage

export const query = graphql`
  query IndexPage($locale: String) {
    home: contentfulPage(slug: { eq: "home" }, node_locale: { eq: $locale }) {
      ...PageFragment
    }
  }
`

HomePage.propTypes = {
  data: PropTypes.shape({
    home: PropTypes.shape({
      metadata: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.shape({
          description: PropTypes.string
        }),
        image: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string
          })
        })
      }),
      sections: PropTypes.arrayOf(PropTypes.shape({}))
    })
  })
}
